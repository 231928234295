import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import answerBaseStyles from './answerBaseStyles'

const styles = {
  root: answerBaseStyles,
}

const PureAnswerParagraph = ({classes, className, children, ...props}) => (
  <Typography className={clsx(classes.root, className)} paragraph {...props}>
    {children}
  </Typography>
)

const AnswerParagraph = withStyles(styles)(PureAnswerParagraph)

export default AnswerParagraph
