import React, {useState} from 'react'
import {isMobile} from '../../hooks/responsive'
import {withStyles} from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMediaText from '../../components/Card/CardMediaText'
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow'
import RegisterCTA from '../../components/RegisterCTA'
import Section from '../../components/Section'
import Typography from '@material-ui/core/Typography'

import benefitsSection from '../../constants/benefitsSection'
import clsx from 'clsx'
import MobileCarousel from '../../components/Carousel/MobileCarousel'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  callToAction: {
    marginTop: '-11px',
  },
  cardContent: {
    lineHeight: '1.5',
    letterSpacing: '0.22px',
  },
  sectionDesktopContainer: {
    height: '400px',
    overflow: 'none',
  },
  sectionMobileContainer: {
    height: '400px',
    position: 'relative',
    overflowY: 'hidden',
  },
  sectionSubContainer: {
    padding: '24px',
    position: 'relative',
    overflow: 'none',
  },
  card: {
    transition: 'all 0.5s',
    outline: '0 none',
    width: '152px',
    height: '152px',
    'user-select': 'none',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      cursor: 'move',
    },
  },
  activeCard: {
    width: '240px',
    minHeight: '325px',
    height: 'fit-content',
    marginTop: '-24px',
  },
})

export default withStyles(styles)(({classes, template, ...props}) => {
  const [sections, setSections] = useState(benefitsSection)
  const callToActionText = 'Quero ser consultora'

  const setMobileSectionActive = currentSlide => {
    setSections(sections.map(sec => ({...sec, isActive: sec.carouselID === currentSlide})))
  }
  const setDesktopSectionActive = section => {
    setSections(sections.map(sec => ({...sec, isActive: sec.id === section.id})))
  }

  return (
    <Section headingTitle="Vantagens de ser uma Consultora  de Beleza" {...props}>
      <Container>
        <div
          className={isMobile() ? classes.sectionMobileContainer : classes.sectionDesktopContainer}>
          {isMobile() ? (
            <MobileBenefitsView sections={sections} setSectionActive={setMobileSectionActive} />
          ) : (
            <DesktopBenefitsView
              sections={sections}
              setSectionActive={setDesktopSectionActive}
              template={template}
            />
          )}
        </div>
        <Typography align="center" className={classes.callToAction} component="h2">
          <RegisterCTA
            id="benefits-register-cta-button"
            data-gtm-category="landing_beneficios"
            data-gtm-label={callToActionText}
            data-gtm-main-action="click"
            data-gtm-category-ga4="landing_vantagens"
            data-gtm-label-ga4={callToActionText.toUpperCase()}
            data-gtm-main-action-ga4="click"
            data-testid="benefits-register-cta-button"
            label={callToActionText}
          />
        </Typography>
      </Container>
    </Section>
  )
})

const MobileBenefitsView = withStyles(styles)(({classes, setSectionActive, sections}) => (
  <MobileCarousel setSectionActive={setSectionActive}>
    {sections.map((benefit, index) => (
      <BenefitCard
        key={benefit.id}
        index={index}
        {...benefit}
        section={benefit}
        onClick={() => {}}
      />
    ))}
  </MobileCarousel>
))

const DesktopBenefitsView = withStyles(styles)(
  ({classes, setSectionActive, sections, template}) => (
    <Grid
      className={classes.sectionSubContainer}
      container
      direction="row"
      justify="center"
      wrap="nowrap"
      alignItems="flex-start"
      spacing={2}>
      {sections.map((benefit, index) => (
        <Grid key={benefit.id} item>
          <BenefitCard
            index={index}
            template={template}
            onClick={setSectionActive}
            section={benefit}
            {...benefit}
          />
        </Grid>
      ))}
    </Grid>
  ),
)

const BenefitCard = withStyles(styles)(
  ({id, title, text, img, imgAltText, index, classes, onClick, isActive, section, ...props}) => {
    const elementId = id && id.replace('#', '')

    if (props.template === 'test-b') {
      isActive = true
    }

    return (
      <Card
        id={elementId && `${elementId}-card`}
        className={clsx(classes.card, isActive && classes.activeCard)}
        data-gtm-category="landing_beneficios"
        data-gtm-click="click_carrossel"
        data-gtm-label={elementId && elementId}
        data-gtm-position={`${elementId}_pos${index}`}
        data-gtm-category-ga4="landing_vantagens"
        data-gtm-click-ga4="click_carousel"
        data-gtm-label-ga4={title}
        data-gtm-position-ga4={index + 1}
        elevation={isActive ? 9 : 0}
        onClick={() => onClick(section)}
        onFocus={() => onClick(section)}
        onKeyPress={() => onClick(section)}
        tabIndex={0}
        {...props}>
        <CardMediaText
          id={elementId && `${elementId}-card-media-text`}
          title={title}
          image={img}
          alt={imgAltText}
          isActive={isActive}
        />
        <Grow in={isActive} timeout={500}>
          <CardContent>
            <Typography
              className={classes.cardContent}
              color="textPrimary"
              component="h3"
              variant="body2">
              {text}
            </Typography>
          </CardContent>
        </Grow>
      </Card>
    )
  },
)
