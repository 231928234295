const styles = theme => ({
  card: {
    borderRadius: '4px',
    borderStyle: 'none',
    borderWidth: 0,
    boxShadow: 'none',
    marginBottom: '16px',
    [theme.breakpoints.down('xs')]: {
      borderColor: 'rgba(187, 187, 187, 0.4)',
      borderStyle: 'solid',
      borderWidth: '1px',
      minHeight: '130px',
    },
  },
  secondCard: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'visible',
    },
  },
  callToAction: {
    clear: 'both',
    marginTop: '16px',
    marginBottom: '0',
    '&::before, &::after': {
      content: ' ',
      display: 'table',
    },
    '&::after': {
      clear: 'both',
    },
  },
  cardMedia: {
    objectFit: 'contain',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      minHeight: '96px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '60%',
    },
  },
  firstCardMedia: {
    [theme.breakpoints.up('sm')]: {
      float: 'left',
      marginRight: '16px',
    },
  },
  secondCardMedia: {
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      marginLeft: '16px',
      marginBottom: '-100px',
      transform: 'translateY(-100px)',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      marginBottom: '-33px',
      transform: 'translateY(-33px)',
    },
    [theme.breakpoints.between('md')]: {
      marginBottom: '-150px',
      transform: 'translateY(-150px)',
    },
  },
  h3: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '0.13px',
    lineHeight: 1.5,
    marginBottom: '8px',
    minWidth: '108px',
    minHeight: '19px',
  },
  paragraph: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 1.5,
    marginBottom: 0,
    objectFit: 'contain',
    widows: 3,
    [theme.breakpoints.down('sm')]: {
      minHeight: '63px',
    },
  },
})

export default styles
