import React from 'react'
import clsx from 'clsx'
import styles from './OurCausesSection.styles'
import useOurCausesImagesData from '../../hooks/ourCausesImages'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Container from '@material-ui/core/Container'
import Image from 'gatsby-image'
import RegisterCTA from '../../components/RegisterCTA'
import Section from '../../components/Section'
import Typography from '@material-ui/core/Typography'

export const PureOurCausesSection = ({classes, className, ...props}) => {
  const callToActionText = 'Quero me cadastrar'
  const data = useOurCausesImagesData()

  return (
    <Section
      className={clsx(classes.root, className)}
      headingTitle="Nossas Causas"
      headingSubtitle="O que uma marca de beleza pode fazer pelo mundo?"
      {...props}>
      <Container maxWidth="md">
        <Card classes={{root: classes.card}}>
          <CardMedia classes={{root: clsx(classes.cardMedia, classes.firstCardMedia)}}>
            <Image
              alt="Mulher negra sentada em um tronco de árvore acima de um rio no meio da Amazônia olhando para o horizonte."
              draggable={false}
              fluid={data.amazonImage.childImageSharp.fluid}
            />
          </CardMedia>
          <CardContent>
            <Typography classes={{h3: classes.h3}} component="h3" variant="h3">
              Amazônia&nbsp;Viva
            </Typography>
            <Typography classes={{paragraph: classes.paragraph}} paragraph>
              Contribuímos na preservação de 1,8 milhão de <wbr />
              hectares na Amazônia, o equivalente a 12 <wbr />
              vezes a cidade de SP e 14 vezes a cidade do&nbsp;RJ.
            </Typography>
          </CardContent>
        </Card>
        <Card classes={{root: clsx(classes.card, classes.secondCard)}}>
          <CardMedia classes={{root: clsx(classes.cardMedia, classes.secondCardMedia)}}>
            <Image
              alt="Imagem de oceano cuja superfície é feita com sacolas de plástico, dando ilusão de ser um mar, entre elas uma se destaca boiando."
              draggable={false}
              fluid={data.plasticOceanImage.childImageSharp.fluid}
            />
          </CardMedia>
          <CardContent>
            <Typography classes={{h3: classes.h3}} component="h3" variant="h3">
              Mais beleza, menos lixo
            </Typography>
            <Typography classes={{paragraph: classes.paragraph}} paragraph>
              Reutilizamos 432 toneladas de plástico nas <wbr />
              embalagens, ou seja, por ano, 9 milhões de <wbr />
              garrafas PET de 2 litros deixam de ir para o&nbsp;lixo.
            </Typography>
          </CardContent>
        </Card>
        <Typography classes={{root: classes.callToAction}} align="center" paragraph component="h2">
          <RegisterCTA
            id="our-causes-register-cta-button"
            data-gtm-category="landing_nossas_causas"
            data-gtm-label={callToActionText}
            data-gtm-main-action="click"
            data-gtm-category-ga4="landing_nossos_diferenciais"
            data-gtm-label-ga4={callToActionText.toUpperCase()}
            data-gtm-main-action-ga4="click"
            data-testid="our-causes-register-cta-button"
            label={callToActionText}
          />
        </Typography>
      </Container>
    </Section>
  )
}

const OurCausesSection = withStyles(styles)(PureOurCausesSection)

export default OurCausesSection
