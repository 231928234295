import React from 'react'

export const schemaOrgJSONLD = [
  {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: 'Como ser Consultora Natura?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'Para se cadastrar na Consultoria de Beleza Natura e muito simples! Basta seguir as instruçoes abaixo:</p><li>Clique no botao <strong>Quero me cadastrar</strong>;</li><li>Preencha os dados solicitados;</li><li>Escolha o nome do seu Espaço Digital;</li><li>Inclua a foto do seu documento.</li></ol><p>Pronto! Você receberá seu código de Consultora e poderá baixar o <a><strong><abbr><em>app</em></abbr> Consultoria</strong></a> para criar a senha e fazer o primeiro pedido.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'O que eu preciso para ser uma Consultora?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Apenas quatro regrinhas básicas para você se tornar uma de nossas consultoras:</p><ol><li>Ser maior de 18 anos;</li><li>Possuir <abbr >CPF</abbr> próprio e Carteira de Identidade (RG);</li><li>Anexar foto da Carteira de Identidade (RG) ou Carteira de Motorista (CNH);</li><li><strong>Realizar o seu primeiro pedido</strong>.</li></ol></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Nao recebi a confirmacao do meu cadastro. O que fazer?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Caso nao tenha recebido a confirmacao do seu cadastro, siga os passos abaixo:</p><ol ><li>Acesse <a>https://consultoria.natura.com.br</a>;</li><li>Clique em <strong>Primeiro acesso?</strong>;</li><li>Preencha com seu número de <abbr >CPF</abbr> ou e-mail.</li></ol><p >Feito isso, e so criar sua senha! Caso seja apresentada uma mensagem de <strong>dados invalidos</strong>, seu cadastro não foi concluido.</p><p>Caso precise de ajuda, entre em contato pelo <a href="tel:03007115566">0300 711 55 66</a> (para telefones celulares) ou <a href="tel:08000115566">0800 011 55 66</a> (para telefones fixos).</p><p>O atendimento por telefone e de segunda a sexta, das <time><abbr>8h</abbr></time> às <time><abbr >22h</abbr></time><wbr> e aos sabados das <time><abbr>9h</abbr></time> as <time><abbr>22h</abbr></time>.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Nao consigo me cadastrar',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Entre em contato conosco pelos telefones:</p><ul><li><a href="tel:03007628872">0300 711 55 66</a> (para telefones celulares)</li><li><a href="tel:08000115566">0800 011 55 66</a>(para telefones fixos)</li></ul><p>O atendimento por telefone é de segunda a sexta, das <time><abbr>8h</abbr></time> às <time><abbr >22h</abbr></time><wbr> e aos sábados das <time><abbr>9h</abbr></time> às <time><abbr>22h</abbr></time>.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Quanto custa para se tornar uma Consultora Natura?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>O seu cadastro é totalmente gratuito! E na Consultoria de Beleza Natura você não precisa comprar um Kit Inicio para começar.</p><p>Faça seu cadastro e escolha os produtos que desejar para compor o seu Primeiro Pedido (mínimo de 50 pontos) e começar a lucrar com as vendas.</p><p>E mais, junto com seu Primeiro Pedido, você vai receber Materiais de Apoio para te ajudar a conquistar muitas vendas! Conte com:<br><ul><li> • Cupons de desconto para seus clientes usarem em seu Espaço Digital (onde você consegue realizar vendas pela internet para todo o Brasil), e você começar com tudo</li><li>• Amostras de Perfumaria para você e para seus clientes ficarem por dentro dos produtos.</li><li>Cupons de desconto para seus clientes usarem em seu Espaço Digital (onde você consegue realizar vendas pela internet para todo o Brasil), e você começar com tudo!</li></ul></p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Qual e a porcentagem de lucro',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>O lucro depende do seu nível no plano de crescimento e por qual ferramenta foi feita a venda. Você pode ganhar até 35% de lucro!</p><p>Saiba mais acessando a seção <a>Ganhos como Consultora</a> nesta página.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Como eu crio minha senha?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Depois de se cadastrar na Consultoria de Beleza Natura, você precisa criar sua senha para começar a fazer os pedidos:</p><ol><li>Acesse <a>https://consultoria.natura.com.br</a>; </li><li>Clique em <strong>Primeiro acesso?</strong>;</li><li>Siga os próximos passos que aparecem na sua tela.</li></ol><p>Com a senha criada, você terá acesso a informações sobre promoções e campanhas, poderá acompanhar as visitas do seu Espaço Digital e muito mais.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Ja sou uma Consultora. Como criar o meu Espaco Digital?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><ol><li>Acesse seu <a><strong><abbr><em>app</em></abbr> Consultoria</strong></a> e clique em <strong>Meu Espaço Digital</strong>;</li><li>Escolha o nome do seu Espaço, confirme seus dados e aceite os termos;</li><li>Pronto! Agora é só acompanhar o <em>status</em> no mesmo local.</li></ol><p>Nossa dica: escolha um endereço simples e fácil de memorizar! E com esse endereço, clientes vão chegar até você.</p></div>',
        },
      },
      {
        '@type': 'Question',
        name: 'Em quanto tempo posso começar a vender?',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '<div><p>Imediatamente! Assim que finalizar o seu cadastro e receber seu código, acesse o site <a href="https://consultoria.natura.com.br" target="__blank">consultoria.natura.com.br</a> para criar sua senha. Com a senha feita, faça login e clique no ícone “Novo Pedido”.</p><br><p>Pronto. Basta incluir os produtos que desejar e começar a lucrar! Ah, depois de fazer o seu Primeiro Pedido, seu Espaço Digital também será aberto para as vendas online!</p><br><p>Dica: acesse o menu Ciclo > Revistas Natura para ter acesso as promoções vigentes e compartilhe a Revista Digital com seus clientes por WhatsApp!</p></div>',
        },
      },
    ],
  },
]
