import React from 'react'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  containerPaper: {
    background: theme.palette.common.white,
    padding: '24px 16px 24px 16px',
    marginLeft: '16px',
  },
  productImage: {
    alignSelf: 'center',
    width: '240px',
  },
  productContent: {
    marginLeft: '18px',
  },
  earningsCalculatorProductsTitle: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '0.875rem;',
    letterSpacing: '0.22px',
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: 0,
  },
  earningsCalculatorProductsDescription: {
    marginTop: 0,
    marginBottom: '16px',
    textAlign: 'left',
    fontSize: '0.875rem;',
    lineHeight: 1.5,
  },
  noSpacing: {
    marginBottom: 0,
  },
})

const EarningsCalculatorCardDesktop = ({classes, productsImage}) => (
  <Paper className={classes.containerPaper}>
    <Grid alignContent="center" container>
      <Grid className={classes.productImage} item>
        {productsImage}
      </Grid>
      <Grid item>
        <div className={classes.productContent}>
          <div>
            <p className={classes.earningsCalculatorProductsTitle}>1. Ekos Castanha</p>
            <p className={classes.earningsCalculatorProductsDescription}>
              100% vegano e ajuda a manter a floresta em pé
            </p>
          </div>
          <div>
            <p className={classes.earningsCalculatorProductsTitle}>2. Tododia Flor de Lis</p>
            <p className={classes.earningsCalculatorProductsDescription}>
              Nutrição prebiótica e ingredientes naturais
            </p>
          </div>
          <div>
            <p className={classes.earningsCalculatorProductsTitle}>3. Essencial Supreme</p>
            <p className={classes.earningsCalculatorProductsDescription}>
              A ousadia do Pará com a sofisticação de Paris
            </p>
          </div>
          <div>
            <p className={classes.earningsCalculatorProductsTitle}>4. Lumina Máscara</p>
            <p className={clsx(classes.earningsCalculatorProductsDescription, classes.noSpacing)}>
              Biotecnologia pró-teia e frasco de plástico verde
            </p>
          </div>
        </div>
      </Grid>
    </Grid>
  </Paper>
)

export default withStyles(styles)(EarningsCalculatorCardDesktop)
