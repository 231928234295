import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

export const heroImage = graphql`
  fragment heroImage on File {
    childImageSharp {
      fluid(maxWidth: 816, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const heroImageQuery = graphql`
  query {
    heroImageVariationA: file(relativePath: {eq: "hero-banner.png"}) {
      ...heroImage
    }
  }
`

/**
 * @property heroImageVariationA
 */
const useHeroImages = () => {
  const data = useStaticQuery(heroImageQuery)
  return data
}

export default useHeroImages
