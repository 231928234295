import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import Link from '@material-ui/core/Link'

const styles = theme => ({
  root: {color: '#227bbd', fontWeight: 500},
})

const PureAnswerLink = ({children, classes, className, ...props}) => (
  <Link className={clsx(classes.root, className)} rel="noopener noreferrer" {...props}>
    {children}
  </Link>
)

const AnswerLink = withStyles(styles)(PureAnswerLink)

export default AnswerLink
