import React, {useState} from 'react'
import GridContainer from '@naturacosmeticos/natds-web/dist/Components/GridContainer'
import GridItem from '@naturacosmeticos/natds-web/dist/Components/GridItem'
import withStyles from '@material-ui/core/styles/withStyles'
import Hidden from '@material-ui/core/Hidden'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import styles from './EarningsCalculator.styles'

import EarningsCalculatorCardDesktop from './EarningsCalculatorCardDesktop'
import EarningsCalculatorResults from './EarningsCalculatorResults'

import EarningsCalculatorCardMobile from './EarningsCalculatorCardMobile'
import Grid from '@material-ui/core/Grid'

const EarningsCalculator = ({classes, productsImage}) => {
  const [tabActive, setTabActive] = useState(0)
  return (
    <>
      <Tabs
        value={tabActive}
        onChange={(e, index) => {
          setTabActive(index)
        }}
        indicatorColor="primary"
        textColor="primary"
        classes={{
          root: classes.customTabs,
          indicator: classes.customTabIndicator,
        }}
        centered>
        <Tab
          id="f2f-sales-clickable"
          data-gtm-category="landing_ganhos_como_consultora"
          data-gtm-main-action="click"
          data-gtm-label="Vendas Presenciais"
          data-gtm-category-ga4="landing_lucros_e_ganhos"
          data-gtm-main-action-ga4="click"
          data-gtm-label-ga4="Vendas Presenciais"
          data-gtm-identifier="button_text"
          data-testid="earnings-calculator-facetoface-sales"
          label="Vendas Presenciais"
          classes={{root: classes.customTab, selected: classes.tabActive}}
        />
        <Tab
          id="online-sales-clickable"
          data-gtm-category="landing_ganhos_como_consultora"
          data-gtm-main-action="click"
          data-gtm-label="Vendas Pela Internet"
          data-gtm-category-ga4="landing_lucros_e_ganhos"
          data-gtm-main-action-ga4="click"
          data-gtm-label-ga4="Consultoria De Beleza Online"
          data-gtm-identifier="button_text"
          data-testid="earnings-calculator-internet-sales"
          label="Consultoria de Beleza Online"
          classes={{root: classes.customTab, selected: classes.tabActive}}
        />
      </Tabs>
      <Hidden only="xs">
        <Grid justify="center" container>
          <Grid item>
            <EarningsCalculatorCardDesktop productsImage={productsImage} />
          </Grid>
          <Grid item>
            <EarningsCalculatorResults tabActive={tabActive} />
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        <GridContainer direction="column">
          <GridItem>
            <EarningsCalculatorCardMobile productsImage={productsImage} />
          </GridItem>
          <GridItem>
            <EarningsCalculatorResults tabActive={tabActive} />
          </GridItem>
        </GridContainer>
      </Hidden>
    </>
  )
}

export default withStyles(styles)(EarningsCalculator)
