import React from 'react'
import Image from 'gatsby-image'
import useHeroImages from '../../hooks/useHeroImage'

export const PureHeroImage = ({data}) => {
  const alt =
    'Mulher sorrindo segurando um celular. Ao lado esquerdo, produtos da Natura em cima da mesa.'
  return (
    <Image
      alt={alt}
      data-gtm-category="landing_hero"
      data-gtm-label={alt}
      data-gtm-main-action="ab_test"
      data-gtm-category-ga4="landing_hero"
      data-gtm-label-ga4={alt}
      data-gtm-main-action-ga4="ab_test"
      draggable={false}
      fluid={data.childImageSharp.fluid}
    />
  )
}

const HeroImage = () => {
  const data = useHeroImages()
  return <PureHeroImage data={data.heroImageVariationA} />
}

export default HeroImage
