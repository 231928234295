import React from 'react'
import PropTypes from 'prop-types'
import useSiteMetadata from '../hooks/useSiteMetadata'
import {Helmet} from 'react-helmet'

const SEO = ({children, description, image, title}) => {
  const {
    defaultDescription,
    defaultImage,
    defaultTitle,
    siteUrl,
    titleTemplate,
    twitterUsername,
  } = useSiteMetadata()
  const seo = {
    description: description || defaultDescription,
    image: image || defaultImage,
    title: title || defaultTitle,
  }
  return (
    <>
      {
        <Helmet defaultTitle={defaultTitle} title={seo.title} titleTemplate={titleTemplate}>
          <html lang="pt-br" />
          <title lang="pt-br" itemProp="name">
            {seo.title}
          </title>
          <meta name="description" content={seo.description} />
          <meta name="twitter:creator" content={twitterUsername} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={siteUrl} />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:title" content={seo.title} />
          <meta property="og:url" content={siteUrl} />
          {children}
        </Helmet>
      }
    </>
  )
}

export default SEO

SEO.propTypes = {
  article: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
}

SEO.defaultProps = {
  article: false,
  description: null,
  image: null,
  pathname: null,
  title: null,
}
