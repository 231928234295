const styles = theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow: 'none',
    marginBottom: '8px',
    minHeight: '48px',
    transition: 'border 0.1s ease-in-out',
    '&:before': {
      display: 'none',
    },
  },
  expanded: {
    border: '1px solid #f4ab34',
  },
  rounded: {
    borderRadius: '4px',
  },
  answerExcerpt: {
    color: '#777777',
    fontSize: '14px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: '0.22px',
    lineHeight: 1.5,
    maxWidth: '530px',
    minHeight: '14px',
    objectFit: 'contain',
    textAlign: 'left',
  },
  expansionPanel: {
    borderTopStyle: 'none',
    borderTopWidth: 0,
  },
})

export default styles
