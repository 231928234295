import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

export const ourCausesImage = graphql`
  fragment ourCausesImage on File {
    childImageSharp {
      fluid(maxWidth: 504, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const ourCausesQuery = graphql`
  query {
    amazonImage: file(relativePath: {eq: "ourcauses/amazonia.png"}) {
      ...ourCausesImage
    }
    plasticOceanImage: file(relativePath: {eq: "ourcauses/oceano_de_plastico.png"}) {
      ...ourCausesImage
    }
  }
`

/**
 * @property amazonImage
 * @property plasticOceanImage
 */
const useOurCausesImagesData = () => {
  const data = useStaticQuery(ourCausesQuery)
  return data
}

export default useOurCausesImagesData
