import React from 'react'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'

const styles = theme => ({
  root: {
    color: '#333333',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 1.5,
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '264px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      letterSpacing: '0.22px',
      minHeight: '21px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '16px',
      letterSpacing: '0.13px',
      maxWidth: '584px',
      minHeight: '24px',
    },
  },
})

const PureQuestion = ({classes, className, children, ...props}) => (
  <Typography className={clsx(classes.root, className)} itemProp="name" {...props} component="h3">
    {children}
  </Typography>
)

const Question = withStyles(styles)(PureQuestion)

export default Question
