import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import answerBaseStyles from './answerBaseStyles'

const styles = {
  base: answerBaseStyles,
  root: {marginLeft: 0, paddingLeft: '2ch'},
}

const PureAnswerInnerList = ({classes, className, children, component, ...props}) => {
  const Component = component
  return (
    <Component className={clsx(classes.base, classes.root, className)} {...props}>
      {children}
    </Component>
  )
}

const AnswerInnerList = withStyles(styles)(PureAnswerInnerList)

export default AnswerInnerList
