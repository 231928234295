import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'

export const benefitImage = graphql`
  fragment benefitImage on File {
    childImageSharp {
      fluid(maxWidth: 240, maxHeight: 192, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const benefitImageQuery = graphql`
  query {
    earningsImage: file(relativePath: {eq: "benefits/beneficios_ganhos_diferenciados.png"}) {
      ...benefitImage
    }
    educationImage: file(relativePath: {eq: "benefits/beneficios_educacao.png"}) {
      ...benefitImage
    }
    healthImage: file(relativePath: {eq: "benefits/beneficios_saude.png"}) {
      ...benefitImage
    }
    acknowledgmentImage: file(relativePath: {eq: "benefits/beneficios_reconhecimento.png"}) {
      ...benefitImage
    }
    onlineSalesImage: file(relativePath: {eq: "benefits/beneficios_vendas_internet.png"}) {
      ...benefitImage
    }
  }
`

/**
 * @property earningsImage
 * @property educationImage
 * @property healthImage
 * @property acknowledgmentImage
 * @property onlineSalesImage
 */
const useBenefitImage = () => {
  const data = useStaticQuery(benefitImageQuery)
  return data
}

export default useBenefitImage
