import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import FlipIcon from '../../../resources/assets/images/earningscalculator/flip_icon.svg'
import FlippedIcon from '../../../resources/assets/images/earningscalculator/flipped_icon.svg'

const styles = theme => ({
  flipButton: {
    position: 'absolute',
    display: 'block',
    width: '40px',
    height: '40px',
    borderRadius: '100%',
    padding: '9px 8px 0px 9px',
    right: '8px',
    marginTop: '45px',
    cursor: 'pointer',
    minWidth: '40px',
    zIndex: 9,
  },
})

const FlipButton = ({classes, className, children, dataLayerProps, flipped, ...props}) => (
  <Button
    data-testid="flip-icon-button"
    id="flip-icon-button"
    aria-label="Alternar entre frente e verso"
    className={classes.flipButton}
    color="primary"
    size="medium"
    variant="contained"
    {...props}>
    {flipped && <img id="flip-icon" alt="Ícone de girar" aria-hidden={true} src={FlipIcon} />}
    {!flipped && <img id="flip-icon" alt="Ícone de girar" aria-hidden={true} src={FlippedIcon} />}
  </Button>
)

export default withStyles(styles)(FlipButton)
