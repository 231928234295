import React, {Children, useReducer} from 'react'
import {Swipeable} from 'react-swipeable'
import {CarouselContainer, CarouselSlot, NEXT, PREV, Wrapper} from './Carousel.styles'

const getOrder = ({index, position, numItems}) => {
  return index - position < 0 ? numItems - Math.abs(index - position) : index - position
}
const initialState = {position: 1, sliding: false, direction: NEXT}

const Carousel = ({children, setSectionActive}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const numItems = Children.count(children)

  const slide = direction => {
    let currentPosition = direction === NEXT ? state.position + 1 : state.position - 1
    currentPosition = currentPosition < 0 ? numItems - 1 : currentPosition
    currentPosition = currentPosition === numItems ? 0 : currentPosition

    dispatch({type: direction, numItems})
    setSectionActive(currentPosition)
    setTimeout(() => {
      dispatch({type: 'stopSliding'})
    }, 50)
  }

  return (
    <Swipeable
      style={{height: '100%'}}
      onSwipedLeft={() => slide(NEXT)}
      onSwipedRight={() => slide(PREV)}
      preventDefaultTouchmoveEvent
      trackMouse>
      <Wrapper>
        <CarouselContainer direction={state.direction} sliding={state.sliding}>
          {Children.map(children, (child, index) => (
            <CarouselSlot
              key={index}
              order={getOrder({index: index, position: state.position, numItems})}>
              {child}
            </CarouselSlot>
          ))}
        </CarouselContainer>
      </Wrapper>
    </Swipeable>
  )
}

function reducer(state, {type, numItems}) {
  switch (type) {
    case 'reset':
      return initialState
    case PREV:
      return {
        ...state,
        direction: PREV,
        sliding: true,
        position: state.position === 0 ? numItems - 1 : state.position - 1,
      }
    case NEXT:
      return {
        ...state,
        direction: NEXT,
        sliding: true,
        position: state.position === numItems - 1 ? 0 : state.position + 1,
      }
    case 'stopSliding':
      return {...state, sliding: false}
    default:
      return state
  }
}

export default Carousel
