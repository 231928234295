import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import React from 'react'
import AnswerLink from './AnswerLink'
import AnswerParagraph from './AnswerParagraph'
import AnswerInnerList from './AnswerInnerList'
import {MDXProvider} from '@mdx-js/react'

export const answerComponents = {
  a: props => <AnswerLink {...props} />,
  p: props => <AnswerParagraph {...props} />,
  ol: props => <AnswerInnerList component="ol" {...props} />,
  ul: props => <AnswerInnerList component="ul" {...props} />,
}

const Answer = ({children, ...props}) => (
  <ExpansionPanelDetails {...props}>
    <MDXProvider components={answerComponents}>
      <div>{children}</div>
    </MDXProvider>
  </ExpansionPanelDetails>
)

export default Answer
