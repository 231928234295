const answerBaseStyles = theme => ({
  color: '#333333',
  fontSize: '14px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: 1.5,
  textAlign: 'left',
})

export default answerBaseStyles
