import React from 'react'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => {
  return {
    header: {
      color: theme.palette.text.primary,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: '16px',
      lineHeight: '1.5',
      [theme.breakpoints.only('xs')]: {
        fontSize: '20px',
      },
    },
    subHeader: {
      color: theme.palette.text.primary,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.5',
      letterSpacing: '0.51px',
      paddingLeft: '20px',
      marginTop: '14px',
    },
  }
}

export default withStyles(styles)(({id, title, classes, subTitle, subHeaderStyle}) => {
  const url = 'teste'
  const rootStyle = subHeaderStyle ? subHeaderStyle : classes.subHeader
  return (
    <hgroup id={id && `${id}-section-header-group`}>
      <Typography
        id={id && `${id}-section-header-title`}
        classes={{root: classes.header}}
        component="h2"
        data-gtm-identifier="element_text"
        variant="h5">
        {title}
      </Typography>
      {subTitle && (
        <Typography
          id={id && `${id}-section-header-subtitle`}
          classes={{root: rootStyle}}
          component="h3"
          variant="subtitle1">
          {subTitle}
          { id === 'ganhos-como-consultora' ? 
            <Link href="https://accounts.natura-avon.com/queroserconsultora"
            target="_blank"> aqui.</Link> :  null }
        </Typography>
      )}
    </hgroup>
  )
})
