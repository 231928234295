const styles = theme => ({
  container: {
    padding: '24px 24px 0 24px',
  },
  heroMobileDescriptionBlock: {
    padding: '0 16px',
    marginTop: '144px !important',
    position: 'relative',
    width: '100%',
    overflowWrap: 'break-word',
  },
  heroCardHeader: {
    fontWeight: '500',
    lineHeight: 1,
    fontSize: '1.875rem',
    marginTop: '-90px',
    position: 'relative',
    padding: '16px',
    color: theme.palette.common.white,
    [theme.breakpoints.up('sm')]: {
      width: '615px',
      marginTop: '-188px',
      fontSize: '3rem',
    },
  },
  heroCardDescription: {
    lineHeight: 1.5,
    fontSize: '1rem',
    position: 'relative',
    color: '#333333',
  },
  heroImageOverlay: {
    position: 'relative',
    '&:after': {
      content: `''`,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: 'linear-gradient(45deg, rgba(0,0,0, 0.5), transparent)',
      width: '100%',
      height: '100%',
    },
  },
  heroButton: {
    width: '100%',
  },
})

export default styles
