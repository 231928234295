import React, {useState} from 'react'
import GridContainer from '@naturacosmeticos/natds-web/dist/Components/GridContainer'
import GridItem from '@naturacosmeticos/natds-web/dist/Components/GridItem'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import FlipButton from './FlipButton'
import ReactCardFlip from 'react-card-flip'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  containerPaperWhite: {
    padding: '19px 16px',
    paddingTop: '7px',
    paddingBottom: '7px',
    background: theme.palette.common.white,
  },
  containerPaperGrey: {
    padding: '9px 16px',
    background: '#eeeeee',
    paddingBottom: '0px',
  },
  productImage: {
    width: '240px',
    margin: '0 auto',
  },
  earningsCalculatorProductsTitle: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '0.7rem;',
    letterSpacing: '0.22px',
    lineHeight: 1.5,
    marginBottom: 0,
    marginTop: 0,
  },
  earningsCalculatorProductsDescription: {
    marginTop: 0,
    marginBottom: '16px',
    textAlign: 'left',
    fontSize: '0.7rem',
    lineHeight: 1.5,
    maxWidth: '124px',
    float: 'left',
  },
  noSpacing: {
    marginBottom: 0,
  },
  cardInfoSpacing: {
    marginLeft: '8px',
  },
})

const EarningsCalculatorCardMobile = ({classes, productsImage}) => {
  const [toggleView, setToggleView] = useState(true)
  return (
    <>
      <FlipButton onClick={() => setToggleView(!toggleView)} flipped={toggleView} />
      <ReactCardFlip isFlipped={!toggleView}>
        <Paper className={classes.containerPaperWhite} onClick={() => setToggleView(!toggleView)}>
          <Grid container>
            <Grid className={classes.productImage} item>
              {productsImage}
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.containerPaperGrey} onClick={() => setToggleView(!toggleView)}>
          <GridContainer>
            <GridItem xs={6} sm={6}>
              <p className={classes.earningsCalculatorProductsTitle}>1. Ekos Castanha</p>
              <p className={classes.earningsCalculatorProductsDescription}>
                100% vegano e ajuda a manter a floresta em pé
              </p>
            </GridItem>
            <GridItem xs={6} sm={6}>
              <div className={classes.cardInfoSpacing}>
                <p className={classes.earningsCalculatorProductsTitle}>2. Tododia Flor de Lis</p>
                <p className={classes.earningsCalculatorProductsDescription}>
                  Nutrição prebiótica e ingredientes naturais
                </p>
              </div>
            </GridItem>
            <GridItem xs={6} sm={6}>
              <p className={classes.earningsCalculatorProductsTitle}>3. Essencial Supreme</p>
              <p className={classes.earningsCalculatorProductsDescription}>
                A ousadia do Pará com a sofisticação de Paris
              </p>
            </GridItem>
            <GridItem xs={6} sm={6}>
              <div className={classes.cardInfoSpacing}>
                <p className={classes.earningsCalculatorProductsTitle}>4. Lumina Máscara</p>
                <p
                  className={clsx(
                    classes.earningsCalculatorProductsDescription,
                    classes.noSpacing,
                  )}>
                  Biotecnologia pró-teia e frasco de plástico verde
                </p>
              </div>
            </GridItem>
          </GridContainer>
        </Paper>
      </ReactCardFlip>
    </>
  )
}

export default withStyles(styles)(EarningsCalculatorCardMobile)
