import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Divider from '@material-ui/core/Divider'

import styles from './EarningsCalculator.styles'
import Grid from '@material-ui/core/Grid'
import EarningsChart from '../../components/Chart'
import { isToggleActive } from '../../../feature-toggle'
import Chart1 from '../../../resources/assets/images/earningscalculator/chart1.svg'
import Chart2 from '../../../resources/assets/images/earningscalculator/chart2.svg'

const valuesForF2FSales = {
  seed: 20,
  bronze: 30,
  silver: 30,
  gold: 32,
  diamond: 35,
}

const valuesForOnlineSales = {
  seed: 20,
  bronze: 30,
  silver: 30,
  gold: 32,
  diamond: 35,
}

const PureEarningsCalculatorResults = ({ classes, tabActive }) => {
  return (
    <Grid direction="column" alignItems="flex-start" className={classes.earningResults} container>
      <Grid className={classes.earningsCalculatorInfo} item>
        <span className={classes.earningsCalculatorChartResult}>
          Seu cliente compra esse conjunto por:
        </span>
        <span className={classes.earningsCalculatorChartMoney}>
          <span className={classes.earningsCalculatorChartSign}>R$</span> 279,80
        </span>
      </Grid>
      <Divider classes={{ root: classes.earningsCalculatorDivider }} />
      <Grid item>
        <p className={classes.earningsCalculatorChartDescription}>
          {tabActive === 0 ? 'O lucro dessa venda presencial, de acordo com o nível da Consultora é:' : 'O ganho desta prestação de serviços de Consultoria de Beleza é calculado de acordo com o seu nível:'}
        </p>
      </Grid>
      <Grid className={classes.chartImage} item>
        <EarningsChart
          id="earnings-calculator-results-chart"
          caption="Gráfico demonstrando os ganhos com venda"
          data-testid="earnings-calculator-chart-internet-seed"
          baseAmount={279.8}
          baseHeight={85}
          values={tabActive === 0 ? valuesForF2FSales : valuesForOnlineSales}
        />
      </Grid>
    </Grid>
  )
}

const EarningsCalculatorResults = withStyles(styles)(PureEarningsCalculatorResults)

export default EarningsCalculatorResults
