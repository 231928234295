import React from 'react'
import Image from 'gatsby-image'
import clsx from 'clsx'
import withStyles from '@material-ui/core/styles/withStyles'
import useBenefitImage from '../../hooks/useBenefitImage'

const styles = {
  root: {
    height: '100%',
    width: '100%',
  },
}

const PureBenefitImage = ({alt, classes, className, data, ...props}) => (
  <Image
    alt={alt}
    className={clsx(classes.root, className)}
    draggable={false}
    fluid={data.childImageSharp.fluid}
    {...props}
  />
)

export const StyledBenefitImage = withStyles(styles)(PureBenefitImage)

const BenefitImage = ({alt, image, ...props}) => {
  const data = useBenefitImage()
  return <StyledBenefitImage alt={alt} data={data[image]} {...props} />
}

export default BenefitImage
