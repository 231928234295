import React from 'react'
import CardMedia from '@material-ui/core/CardMedia'
import {withStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const styles = theme => ({
  benefitCardContainer: {
    backgroundColor: theme.palette.primary.dark,
    transition: 'all 0.5s',
    width: '100%',
    height: 152,
  },
  benefitWrapper: {
    position: 'relative',
  },
  gradient: {
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'block',
    top: 0,
    zIndex: 0,
  },
  textOverlay: {
    transition: 'all 0.5s',
    position: 'absolute',
    bottom: 0,
    fontWeight: 'bold',
    left: '16px',
    letterSpacing: '0.13px',
    textAlign: 'left',
    color: '#ffffff',
    zIndex: 1,
    lineHeight: '1.25',
  },
  activeTextOverlay: {
    fontSize: '26px',
  },
  activeBenefitCardContainer: {
    width: '240px',
    height: '192px',
  },
})

export default withStyles(styles)(({id, title, image, alt, classes, isActive}) => (
  <div id={id && `${id}-wrapper`} className={classes.benefitWrapper}>
    <CardMedia
      id={id && `${id}`}
      className={clsx(classes.benefitCardContainer, isActive && classes.activeBenefitCardContainer)}
      component="div"
      alt={alt}>
      {image}
    </CardMedia>
    <Typography
      id={id && `${id}-title`}
      dangerouslySetInnerHTML={{__html: title}}
      className={clsx(classes.textOverlay, isActive && classes.activeTextOverlay)}
      gutterBottom
      variant="subtitle1"
    />
    <div className={classes.gradient} />
  </div>
))
