import {graphql, useStaticQuery} from 'gatsby'

/**
 * @property description: string
 * @property defaultImage: string
 * @property defaultTitle: string
 * @property siteUrl: string
 * @property titleTemplate: string
 * @property twitterUsername: string
 */
const useSiteMetadata = () => {
  const {site} = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultImage: image
          defaultTitle: title
          defaultDescription: description
          siteUrl
          titleTemplate
          twitterUsername
        }
      }
    }
  `)
  return site.siteMetadata
}

export default useSiteMetadata
